<template lang="pug">
AppButton(:label="$t('login.quick') + ' (' + countInvoicesToPay + ')'" color="primary" @click="goQuickPayment" v-if="countInvoicesToPay > 0")
//- AppFormInput.mb-2(type="select" :label="$t('invoices.filter_by')" :options="invoiceTypes" v-model:value="currentInvoiceType")
span(class="label") {{ $t('invoices.filter_by') }}
select(name="invoice_type" v-model="currentInvoiceType" class="input" @change="getListInvoices")
  option(:value="item.value" v-for="item in invoiceTypes" :key="item.value")
    | {{ item.label }}
//- pre {{ invoices }}
.cards
  .cards__body
    router-link(v-for="(invoice, i) in invoices" :to="{name: 'InvoicesView', params: { id: invoice.id } }" v-slot="{ navigate }" custom)
      AppInvoice(@click="navigate" :invoice="invoice")
  AppButton(label="Cargar más" v-if="hasMoreItems" @click="getInvoices(null)")
</template>

<script>
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";

import { axios } from "@/utils/axios";
import { store } from "@/store";

import AppInvoice from "@/components/AppInvoice";
import AppFormInput from "@/components/Form/AppFormInput";
import AppButton from "@/components/AppButton";

export default {
  components: { AppInvoice, AppFormInput, AppButton },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const { id } = route.params;
    const invoices = ref([]);
    const loading = ref(true);
    const totalItems = ref(0);
    const currentInvoiceType = ref("not_paid");
    const page = ref(1);
    const serviceNumber = ref("");
    const countInvoicesToPay = ref(0);

    const invoiceTypes = [
      {
        value: "not_paid",
        label: t("invoices.not_payed"),
      },
      {
        value: "paid",
        label: t("invoices.paid"),
      },
    ];

    const hasMoreItems = computed(() => {
      return parseInt(totalItems.value) > invoices.value.length;
    });

    const getInvoices = async (type = null, reset = false) => {
      try {
        store.APP_LOADING = true;
        store.APP_TITLE = "";
        store.I18N_TITLE = false;

        if (reset) {
          page.value = 0;
        }

        if (!type) {
          type = currentInvoiceType.value;
        }

        const limit = 6;
        const offset = limit * page.value;

        const query = { type, offset, limit };
        const params = new URLSearchParams(query).toString();

        const { data } = await axios.get(`invoicesodoo/list/${id}?${params}`);

        if (data.payload[0]?.servicio_id) {
          store.APP_TITLE = `${t("services.service")} ${
            data.payload[0].servicio_id[1]
          }`;
          serviceNumber.value = data.payload[0].servicio_id[1];
        }

        invoices.value = [...invoices.value, ...data.payload];
        const { total_registros = 0 } = data.metadata;

        page.value = page.value + 1;
        totalItems.value = total_registros;
        countInvoicesToPay.value = invoices.value.filter(
          (invoice) =>
            invoice.payment_status == false &&
            invoice.payment_state == "not_paid"
        ).length;
        // console.log(invoices.value);
      } catch (error) {
        console.log(error);
      } finally {
        store.APP_LOADING = false;
      }
    };

    const goQuickPayment = () => {
      // console.log("quick payment");
      const service_id = serviceNumber.value;
      router.push({ name: "QuickPaymentInvoices", params: { id, service_id } });
      // router.push("/")
    };

    watch(
      () => currentInvoiceType.value,
      async (val) => {
        invoices.value = [];
        await getInvoices(val, true);
      },
      { immediate: true }
    );

    return {
      invoices,
      loading,
      invoiceTypes,
      currentInvoiceType,
      hasMoreItems,
      getInvoices,
      goQuickPayment,
      countInvoicesToPay,
    };
  },
};
</script>

<style lang="scss" scoped>
.cards {
  @apply pb-4;
  &__body {
    @apply mb-4;
    @apply grid;
    @apply grid-cols-1;
    @apply gap-4;
    @screen sm {
      @apply grid-cols-2;
    }
    @screen md {
      @apply grid-cols-2;
    }
    @screen xl {
      @apply grid-cols-3;
    }
  }
}

.input {
  @apply mb-3;
  @apply block;
  @apply w-full;
  @apply rounded-md;
  @apply border-gray-300;
  @apply shadow-sm;
  @apply px-3;
  @apply py-2;
  @apply text-bluegray-700;
  @apply text-sm;
  @apply transition-all;
  @apply outline-none;
  &:focus:invalid {
    @apply border-red-300;
    @apply shadow-red-300-md;
  }
  &:focus {
    @apply border-gignetblue-300;
    @apply ring;
    @apply ring-gignetblue-200;
    @apply ring-opacity-50;
    @apply ring-offset-2;
  }

  &.disabled {
    @apply bg-bluegray-50;
    @apply text-bluegray-400;
  }
}

.label {
  @apply text-bluegray-400;
  @apply uppercase;
  @apply text-sm;
  @apply font-medium;
  @apply mb-2;
  @apply inline-block;
  @apply tracking-wide;
}
</style>
